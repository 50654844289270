<script>
import Libraries from '@/components/Libraries.vue'
import SnsBaseCommons from '@/components/sns/SnsBaseCommons.vue'

export default {
  mixins: [Libraries, SnsBaseCommons],
  data() {
    return this.initialize()
  },
  created() {
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    $route(/*to, from*/) {
      this.reset();
      this.fetchData()
    }
  },
  methods: {
    initialize() {
      return {
        space_id: Number(this.$route.params.space_id),
        room_id: Number(this.$route.params.room_id) === 0 ? null : Number(this.$route.params.room_id),
        post_id: Number(this.$route.params.post_id) === 0 ? null : Number(this.$route.params.post_id),
        page: this.$route.query.page == null ? 1 : Number(this.$route.query.page),
        tags: this.$route.query.tags == null ? [] : this.$route.query.tags.split(' '),
        filter: this.$route.query.filter == null ? '' : this.$route.query.filter,
        order: this.$route.query.order == null ? '' : this.$route.query.order,
        fetch_data: null,
        post: null,

        new_post: {},
        new_comment: {},
        show_join_menu: false,
        infiniteLoadingEnabled: true
      }
    },
    reset() {
      Object.assign(this.$data, this.initialize());
    },

    // spaceのタイトル
    space_title() {
      if (this.fetch_data.room) {
        return this.fetch_data.room.title
      } else {
        return this.fetch_data.space.title
      }
    },

    // spaceの説明
    space_description() {
      if (this.fetch_data.room) {
        return this.fetch_data.room.description
      } else {
        return this.fetch_data.space.description
      }
    },

    // 新規投稿は可能か
    post_addable() {
      if (this.fetch_data.room) {
        return this.fetch_data.room.post_addable
      } else if (this.fetch_data.space.id === 1) {
        // time-line
        // console.log(this.fetch_data.space.user.staff)
        return (!this.fetch_data.space.user.staff && this.fetch_data.space.user.follow) || this.fetch_data.space.user.id === this.current_user.id
      } else if (this.fetch_data.space.category === 'category_social') {
        // social
        return this.fetch_data.space.post_addable && this.fetch_data.space.user_join
      } else {
        return this.fetch_data.space.post_addable
      }
    },

    // 新規コメントは可能か
    comment_addable() {
      return this.fetch_data.room ? this.fetch_data.room.comment_addable : this.fetch_data.space.comment_addable
    },

    // 新規コメントは可能か
    comment_addable_from_post(post) {
      return post.comment_addable
    },

    // 新規投稿フォームの表示・非表示
    togglePostForm() {
      const edit_mode = this.new_post.edit_mode ? !this.new_post.edit_mode : true
      this.new_post = {
        space_id: this.space_id,
        space_user_id: this.fetch_data.space.space_user_id,
        room_id: (this.$route.params.nav_category === 'timeline' ? null : this.room_id),
        body: null,
        status: 'status_published',
        sns_tag: '',
        files: [],
        edit_mode: edit_mode
      }
    },

    // 投稿の新規追加
    newPost(post, save_post) {
      post.edit_mode = false
      //this.fetch_data.posts.unshift(save_post)
      //console.log(save_post)
      if (save_post.sns_space_id === 1) {
        //this.$router.push({
        //  name: 'SnsTimeline',
        //  params: {user_id: this.fetch_data.space.space_user_id}
        //})
        this.$router.go()
      } else {
        this.$router.push({
          name: 'SnsSpace',
          params: this.$route.params,
          hash: `#post-${save_post.id}`
        })
      }
    },

    // 投稿の下書き保存
    draftSaved(post, /*save_post*/) {
      post.edit_mode = false
    },

    spaceUrl() {
      const category = this.fetch_data.space.category.substring(9)
      if (category === 'timeline') {
        const user_id = this.post.user_id
        return `/sns/timeline/${user_id}`
      } else {
        const space_id = this.fetch_data.space.id
        const room_id = !this.fetch_data.room ? 0 : this.fetch_data.room.id
        return `/sns/space/${category}/${space_id}-${room_id}`
      }
    }
  }
}
</script>
