<template>
  <div id="container" v-if="fetch_data">
    <template v-if="fetch_data.space.user.status === 'status_withdrawal'">
      <section id="mypage" class="timeline">
        <div class="wrap">
          <h2 class="sub_ttl"><span>プロフィール</span></h2>
          <p class="alnC mt50 mb50">このユーザーは退会しました。</p>
          <a @click="$router.go(-1)" class="submit light skeleton">前のページにもどる</a>
        </div>
      </section>
    </template>
    <template v-else>
      <vue-editor v-show="false"/> <!-- dummy -->
      <section id="mypage" class="timeline">
        <div class="wrap">
          <h2 class="sub_ttl"><span>{{ fetch_data.space.user.sns_user_name }} さんのプロフィール</span></h2>
          <div class="cts_box" v-if="$route.meta.current_user.id === fetch_data.space.user.id">
            <div class="cts_box">
              <div class="student_info flex">
                <div class="stdt_icon achievement" v-bind:class="fetch_data.space.user.dan">
                  <img v-bind:src="fetch_data.space.user.profile_image_url" alt="">
                  <div class="btnBox">
                  </div>
                </div>
                <div class="student_detail">
                  <dl class="flex">
                    <dt>ユーザーネーム（表示名）</dt>
                    <dd>{{ fetch_data.space.user.sns_user_name }}</dd>
                  </dl>
                  <dl class="flex follow_now">
                    <dt v-if="fetch_data.space.user.followers_count">
                      現在 {{ fetch_data.space.user.followers_count }}人の塾生にフォローされています
                    </dt>
                    <dt v-if="!fetch_data.space.user.followers_count">
                      現在フォローされている塾生はおりません
                    </dt>
                    <dd>
                      <router-link :to="{ name: 'UsersCustomerSnsDashboard'}" class="submit basic">
                        各種設定と活動状況
                      </router-link>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div class="cts_box" v-if="$route.meta.current_user.id !== fetch_data.space.user.id">
            <div class="student_info flex"
                 v-bind:class="{'mute': fetch_data.space.user.mute, 'block': fetch_data.space.user.block}">
              <div class="stdt_icon">
                <div class="mute_badge" v-on:click="unMute"></div>
                <img v-bind:src="fetch_data.space.user.profile_image_url" alt="">
                <div class="btnBox" v-if="!fetch_data.space.user.follow">
                  <a href="javascript:void(0)" class="submit light skeleton" v-on:click="followClicked">フォローする</a>
                </div>
                <div class="btnBox" v-if="fetch_data.space.user.follow">
                  <a href="javascript:void(0)" class="submit light skeleton" v-on:click="followClicked">フォローを解除</a>
                </div>
              </div>
              <div class="student_detail">
                <dl class="flex">
                  <dt>ユーザーネーム（表示名）</dt>
                  <dd>{{ fetch_data.space.user.sns_user_name }}</dd>
                </dl>
                <template v-if="fetch_data.space.user.status === 'status_staff' && fetch_data.space.user.profile">
                  <dl class="flex profile">
                    <dt>プロフィール</dt>
                    <dd v-html="fetch_data.space.user.profile" style="font-size: 18px"></dd>
                  </dl>
                </template>
                <template v-if="fetch_data.space.user.status !== 'status_staff'">
                  <dl class="flex">
                    <dt>塾生番号</dt>
                    <dd>
                      {{ fetch_data.space.user.number }}
                      <template v-if="fetch_data.space.user.sns_rank">
                        <span v-if="fetch_data.space.user.status === 'status_staff'" class="rank_tag c01">スタッフ</span>
                        <!--スタッフ-->
                        <span v-else-if="fetch_data.space.user.sns_rank === 'rank_regular_plus'" class="rank_tag c01">本科プラス</span>
                        <!--本科プラス-->
                        <span
                            v-else-if="fetch_data.space.user.sns_rank === 'rank_regular' || fetch_data.space.user.sns_rank === 'rank_regular_default'"
                            class="rank_tag c02">本科</span><!--本科-->
                        <span v-else-if="fetch_data.space.user.sns_rank === 'rank_preparatory'"
                              class="rank_tag c03">予科</span><!--予科-->
                        <span v-else-if="fetch_data.space.user.sns_rank === 'rank_student'"
                              class="rank_tag c05">学生科</span><!--学生科-->
                        <span v-else-if="false /*fetch_data.space.user.sns_rank === 'rank_corporation'*/"
                              class="rank_tag c05">法人科</span>
                      </template>
                    </dd>
                  </dl>
                  <dl class="flex">
                    <dt>入塾日</dt>
                    <dd>
                      {{ moment(fetch_data.space.user.join_at, 'YYYY') }}<span>年</span>
                      {{ moment(fetch_data.space.user.join_at, 'MM') }}<span>月</span>
                      {{ moment(fetch_data.space.user.join_at, 'DD') }}<span>日</span>
                    </dd>
                  </dl>
                </template>
              </div>

              <div class="activities flex">
                <div>
                  <h5>現在フォロー中の塾生</h5>
                  <div class="data"><span>{{ delimited(fetch_data.space.user.follows_count) }}</span>人</div>
                </div>
                <div>
                  <h5>この人をフォローしている塾生</h5>
                  <div class="data"><span>{{ delimited(fetch_data.space.user.followers_count) }}</span>人</div>
                </div>
                <div>
                  <h5>参加中のテーマ別グループ</h5>
                  <div class="data"><span>{{ delimited(fetch_data.space.user.join_space_count) }}</span></div>
                </div>
              </div>

              <div class="user_settings flex">
                <a href="javascript:void(0)" class="blue bold no_bdr mute" v-if="!fetch_data.space.user.mute"
                   @click="mute">この塾生をミュートする</a>
                <a href="javascript:void(0)" class="blue bold no_bdr mute" v-if="fetch_data.space.user.mute"
                   @click="unMute">この塾生のミュートを解除する</a>

                <a href="javascript:void(0)" class="red bold no_bdr block" v-if="!fetch_data.space.user.block"
                   @click="block">この塾生をブロックする</a>
                <a href="javascript:void(0)" class="red bold no_bdr block" v-if="fetch_data.space.user.block"
                   @click="unBlock">この塾生のブロックを解除する</a>
              </div>
            </div>

          </div>

          <TimelineQrcode v-if="$route.meta.current_user.id === fetch_data.space.user.id" />

          <h3 class="min_ttl">{{ fetch_data.space.user.sns_user_name }} さんのタイムライン</h3>
          <div class="cts_box" v-if="post_addable()">
            <div class="acc_menu post_form">
              <h3 class="min_ttl acc_tab" v-bind:class="{'open': new_post.edit_mode}" v-on:click="togglePostForm">
                <span>投稿内容の作成</span>
              </h3>
              <transition>
                <SnsPostForm :post="new_post" :tags="fetch_data.tags" v-if="new_post.edit_mode" v-on:saved="newPost"
                             v-on:draft_saved="draftSaved"/>
              </transition>
            </div>

          </div>

          <div class="cts_box search" v-if="false">
            <h3>表示内容の絞り込み</h3>
            <p>チェックを入れた内容で投稿の表示を絞り込みます。</p>
            <form action="">
              <div class="radio">
                <input id="radio01" type="radio" v-model="filter" value="" v-on:change="pageChanged(1)">
                <label for="radio01">すべての投稿を表示</label>

                <input id="radio03" type="radio" v-model="filter" value="links" v-on:change="pageChanged(1)">
                <label for="radio03">関連リンクあり</label>

                <input id="radio04" type="radio" v-model="filter" value="video_files" v-on:change="pageChanged(1)">
                <label for="radio04">動画データあり</label>

                <input id="radio05" type="radio" v-model="filter" value="audio_files" v-on:change="pageChanged(1)">
                <label for="radio05">音声データあり</label>

                <input id="radio06" type="radio" v-model="filter" value="image_files" v-on:change="pageChanged(1)">
                <label for="radio06">画像データあり</label>

                <input id="radio07" type="radio" v-model="filter" value="pdf_files" v-on:change="pageChanged(1)">
                <label for="radio07">PDF資料あり</label>

              </div>
              <div class="checkbox">
                <span v-for="(tag, key) in tags" :key="tag">
                <input v-bind:id="'check-' + key" type="checkbox" value="1" checked="checked">
                <label v-bind:for="'check-' + key">
                  <span class="tag" v-on:click="tagClearClicked(tag)">タグ名：{{ tag }}</span>
                </label>
                </span>
              </div>
            </form>
          </div>
          <div class="cts_box" v-show="fetch_data.posts.length === 0">
            まだ投稿されていません
          </div>

          <div class="cts_box" v-show="fetch_data.posts.length">
            <div class="sort flex">
              <p>並び替え</p>
              <select v-model="order" v-on:change="pageChanged(1)">
                <option value="">更新順</option>
                <option value="published_at_desc">投稿の新しい順</option>
                <option value="published_at_asc">投稿の古い順</option>
              </select>
            </div>

            <!--5件ごとのページ切り替え-->

            <!--フィード1セット-->
            <template v-for="post in fetch_data.posts">
              <div v-bind:id="'post-' + post.id" class="anchor_point" :key="'anchor-' + post.id"></div>
              <div class="feed_box" v-bind:id="'feed-' + post.id" v-bind:class="post.status" :key="'feed-' + post.id">
                <!-- post menu -->
                <SnsPostMenu :post="post" :show_permalink_menu="true" @editPost="editPost" @reportPost="reportPost"
                             @deletePost="deletePost" @hidePost="hidePost"
                             @playedTest="playedTest" @audioPlayedTest="audioPlayedTest"
                />

                <div class="user_info flex">
                  <div class="icon achievement" v-bind:class="post.user.dan">
                    <router-link
                        :to="{ name: 'SnsTimeline', params: {user_id: post.user.id}}">
                      <img v-bind:src="post.user.profile_image_url" alt="">
                    </router-link>
                  </div>
                  <div class="detail">
                    <div class="user_name">
                      <router-link :to="{ name: 'SnsTimeline', params: {user_id: post.user.id}}">
                        {{ post.user.sns_user_name }}
                      </router-link>
                    </div>
                    <div class="post_day">{{ moment(post.published_at, 'YYYY/MM/DD HH:mm') }}</div>
                    <div class="favorite" v-bind:class="{active: post.favorite}">
                      <a href="javascript:void(0)" class="flex" @click="favoritePost(post)">
                        <img v-bind:src="`/common/images/icon/star_favorite_${post.favorite}.svg`" alt="お気に入り">
                        <p>お気に入り</p>
                      </a>
                    </div>
                  </div>
                  <div class="hashtag" v-if="post.sns_tag">
                    <router-link :to="{name: 'SnsSearch', query: {query: tag.substring(1)}}" v-for="tag in post.sns_tag.split(' ')" :key=tag>
                      {{ tag }}
                    </router-link>
                  </div>
                </div>
                <div class="tag">
                  <a href="javascript:void(0)" @click="scrollTop()">{{ space_title() }}</a>
                </div>
                <div class="post_title" v-if="post.title && post.title.length > 0">
                  {{ post.title }}
                </div>
                <div class="post_body ql-container ql-snow" v-if="!post.edit_mode" v-bind:id="'post-body-' + post.id"
                     v-bind:class="{height_rimit: (post.height_limit === 1 || post.height_limit === 2), open: post.height_limit === 2}">
                  <div class="inner">
                    <div class="post_body ql-editor" v-html="bodyEssence(post.body)"></div>
                    <!-- ogp tag -->
                    <SnsOgpTags :ogp_tags="post.ogp_tags"/>
                    <!-- hedge txt -->
                    <SnsPostHedgeText v-if="post.user.dan === 'rank_staff'" />
                  </div>
                  <div class="post_more" @click="post.height_limit = post.height_limit == 1 ? 2 : 1"><span></span></div>
                </div>

                <transition>
                  <div class="cts_box" v-if="post.edit_mode">
                    <div class="acc_menu post_form post_form_edit">
                      <h3 class="min_ttl acc_tab" v-bind:class="{'open': post.edit_mode}" v-on:click="editPost(post)">
                        <span>投稿内容の編集</span>
                      </h3>
                      <SnsPostForm :post="post" :tags="fetch_data.tags" v-on:saved="savePost"/>
                    </div>
                  </div>
                </transition>

                <div v-if="!post.edit_mode">
                  <!-- post files -->
                  <SnsFiles :files="post.files"/>

                  <div class="post_info flex">
                    <div class="nice_box flex">
                      <div class="nice" v-on:mouseover="likeOver(post)" v-on:mouseleave="likeLeave(post)"
                           @click="likeClick(post)"><span>{{ delimited(post.like_user_count) }}</span></div>
                      <div class="btn_nice" v-bind:class="{active: post.like_user}" v-on:click="likeUserPost(post)">
                        <span>いいね！</span>
                      </div>
                      <SnsLikeUserList :post="post" v-if="post.show_like_list"/>
                      <SnsLikeUserModal :post="post" v-if="post.show_like_modal" @close="likeModalClose(post)"
                                        @follow="follow" @un_follow="unFollow"/>
                    </div>
                    <div class="comment">コメント<span>{{ delimited(post.comment_count) }}</span>件</div>
                  </div>
                  <div class="comment_more" v-show="post.comments.length < post.comment_count"
                       v-on:click="fetchComments(post)">以前のコメントを全て見る（<span>{{
                      delimited(post.comment_count)
                    }}</span>件）
                  </div>

                  <!--コメント1セット-->
                  <div v-if="comment_addable()">
                    <div class="comment_body flex" v-for="(comment, key) in post.comments" :key="key"
                         v-bind:id="'comment-' + comment.id">
                      <div class="icon achievement" v-bind:class="comment.user.dan">
                        <router-link
                            :to="{ name: 'SnsTimeline', params: {user_id: comment.user.id}}">
                          <img v-bind:src="comment.user.profile_image_url" alt="">
                        </router-link>
                      </div>
                      <div class="comment_post_box">
                        <div class="inner">
                          <!-- comment menu -->
                          <SnsCommentMenu :comment="comment" @editComment="editComment(comment)"
                                          @reportComment="reportComment(post, comment)"
                                          @deleteComment="deleteComment(post, comment)"/>

                          <div class="head flex">
                            <div class="user_name">
                              <router-link :to="{ name: 'SnsTimeline', params: {user_id: comment.user.id}}">
                                {{ comment.user.sns_user_name }}
                              </router-link>
                            </div>
                            <div class="post_day">{{ moment(comment.updated_at, 'YYYY/MM/DD HH:mm') }}</div>
                          </div>
                          <div class="comment_post_body ql-container ql-snow" v-if="!comment.edit_mode">
                            <div class="post_body ql-editor" v-html="bodyEssence(comment.body)"></div>
                          </div>

                          <transition>
                            <SnsPostCommentForm :post="post" :comment="comment" v-if="comment.edit_mode"
                                                v-on:saved="saveComment"/>
                          </transition>
                        </div>
                        <div v-if="!comment.edit_mode">
                          <SnsFiles :files="comment.files"/>
                          <div class="comment_info flex">
                            <div class="comment_nice" v-bind:class="{active: comment.like_user}"
                                 v-on:click="likeUserComment(comment)">いいね！
                            </div>
                            <div class="count" v-on:mouseover="commentLikeOver(comment)"
                                 v-on:mouseleave="commentLikeLeave(comment)" @click="commentLikeClick(comment)">
                              （<span>{{ delimited(comment.like_user_count) }}</span>）
                            </div>
                            <SnsLikeUserList :comment="comment" v-if="comment.show_like_list"/>
                            <SnsLikeUserModal :comment="comment" v-if="comment.show_like_modal"
                                              @close="commentLikeModalClose(comment)" @follow="follow"
                                              @un_follow="unFollow"/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--コメント1セットここまで-->
                    <div class="post_btn flex">
                      <div class="btn_comment" v-if="comment_addable()"
                           v-on:click="togglePostCommentForm(post)">
                        <span>コメントする</span></div>
                    </div>
                  </div>

                  <transition>
                    <SnsPostCommentForm :post="post" :comment="new_comment"
                                        v-if="new_comment.edit_mode && new_comment.sns_post_id === post.id"
                                        v-on:saved="newComment"/>
                  </transition>
                </div>
              </div>
            </template>
            <infinite-loading ref="infiniteLoading" spinner="spiral" @infinite="infiniteHandler"
                              v-if="infiniteLoadingEnabled">
              <span slot="no-more">これ以上の投稿はありません</span>
            </infinite-loading>
            <!--pager-->
            <Pagination :pagination="fetch_data.pagination" v-on:changed="pageChanged" v-if="!infiniteLoadingEnabled"/>
            <!--//pager-->
          </div>
        </div>
      </section>
    </template>

    <div class="modal open" v-if="show_follow_modal">
      <template v-if="fetch_data.space.user.follow">
        <div class="modal_box">
          <div class="header">
            <h4>{{ fetch_data.space.user.sns_user_name }} さんはフォロー済みです</h4>
            <div class="close modal_close" v-on:click="show_follow_modal = false"></div>
          </div>
          <div class="inner">
            <div class="btn_wrap">
              <a href="javascript:void(0);" class="submit gray modal_close"
                 v-on:click="show_follow_modal = false">閉じる</a>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="modal_box">
          <div class="header">
            <h4>{{ fetch_data.space.user.sns_user_name }} さんをフォローしますか？</h4>
            <div class="close modal_close" v-on:click="show_follow_modal = false"></div>
          </div>
          <div class="inner">
            <div class="btn_wrap">
              <a href="javascript:void(0);" class="submit light"
                 v-on:click="followClicked() ; show_follow_modal = false">フォローする</a>
            </div>
            <div class="btn_wrap">
              <a href="javascript:void(0);" class="submit gray modal_close"
                 v-on:click="show_follow_modal = false">閉じる</a>
            </div>
          </div>
        </div>
      </template>
      <div class="ovr"></div>
    </div>

  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Pagination from '@/components/Pagination.vue'
import SnsOgpTags from '@/components/sns/SnsOgpTags.vue'
import SnsFiles from '@/components/sns/SnsFiles.vue'
import SnsCommons from '@/components/sns/SnsCommons.vue'
import SnsPostMenu from '@/components/sns/SnsPostMenu.vue'
import SnsPostForm from '@/components/sns/SnsPostForm.vue'
import SnsPostCommentForm from '@/components/sns/SnsPostCommentForm.vue'
import SnsCommentMenu from '@/components/sns/SnsCommentMenu.vue'
import SnsLikeUserList from "@/components/sns/SnsLikeUserList";
import SnsLikeUserModal from '@/components/sns/SnsLikeUserModal'
import {VueEditor} from 'vue2-editor';
import TimelineQrcode from '@/views/users/customer/elements/account/_timeline_qrcode'
import SnsPostHedgeText from "@/components/sns/SnsPostHedgeText.vue"

export default {
  mixins: [Libraries, SnsCommons],
  components: {
    SnsPostHedgeText,
    Pagination,
    SnsOgpTags,
    SnsFiles,
    SnsPostMenu,
    SnsPostForm,
    SnsPostCommentForm,
    SnsCommentMenu,
    SnsLikeUserList,
    SnsLikeUserModal,
    TimelineQrcode,
    VueEditor
  },
  data() {
    return {
      show_follow_modal: this.$route.params.command === 'follow'
    }
  },
  mounted() {
    if (this.show_follow_modal) {
      history.pushState('', '', 'follow_modal')
    }
  },
  methods: {
    // 無限スクロール
    infiniteHandler($state) {
      if (this.fetch_data.pagination.last_page) {
        $state.complete()
      } else {
        this.page += 1
        this.fetchData()
      }
    },

    fetchData() {
      this.$route.params.nav_category = 'timeline'
      //this.$route.params.space_id = 1
      //this.$route.params.room_id = this.$route.params.user_id
      this.space_id = 1
      this.room_id = this.$route.params.user_id
      if (this.fetch_data === null || !this.infiniteLoadingEnabled) {
        this.scrollTop()
        this.startAjax()
      }
      this.axios
          .get(`${this.env.api_base_url}sns/posts_space.json`, {
            params: {
              space_id: this.space_id,
              room_id: this.room_id,
              follow_modal: this.show_follow_modal ? 1 : null,
              tags: this.tags.join(' '),
              page: this.page,
              filter: this.filter,
              order: this.order,
            },
          })
          .then(response => {
            // console.log(response.data)
            this.addFrontParams(response.data)
            if (this.fetch_data === null || !this.infiniteLoadingEnabled) {
              this.fetch_data = response.data
            } else {
              this.fetch_data.posts = this.fetch_data.posts.concat(response.data.posts)
              this.fetch_data.pagination = response.data.pagination
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            if (this.$refs.infiniteLoading) this.$refs.infiniteLoading.stateChanger.loaded()
            // 投稿の要素の高さを調べる
            this.checkPostBodyHeight(this.fetch_data.posts)
            this.finishAjax()
            if (location.hash) {
              setTimeout(() => {
                this.scrollTo(location.hash)
              }, 200)
            }
            this.$nextTick(() => {
              this.fetch_data.posts.forEach((post) => {
                this.vimeoPlayTrigger(post)
              });
            });
          })
    },

    // フォロー・フォロー解除
    followClicked() {
      this.axios
          .post(`${this.env.api_base_url}sns/follow_user.json`, {
            id: this.$route.params.user_id
          })
          .then(response => {
            // console.log(response.data)
            this.fetch_data.space.user.follow = response.data.follow
            this.fetch_data.space.post_addable = this.fetch_data.space.user.follow
            //this.$router.go()
          })
    },

    // tag クリア
    tagClearClicked(tag) {
      const index = this.tags.indexOf(tag)
      if (index !== -1) {
        this.tags.splice(index, 1)
        this.pageChanged(1)
      }
    },

    // tag 選択
    tagClicked(tag) {
      if (this.tags.indexOf(tag) === -1) {
        this.tags.push(tag)
        this.pageChanged(1)
      }
    },

    // ページネーション
    pageChanged(page) {
      const tags = this.$route.query.tags == null ? '' : this.$route.query.tags
      const filter = this.$route.query.filter == null ? '' : this.$route.query.filter
      const order = this.$route.query.order == null ? '' : this.$route.query.order
      if (this.page !== page || tags !== this.tags || filter !== this.filter || order !== this.order) {
        let query = {}
        if (this.tags.length > 0) query.tags = this.tags.join(' ')
        if (this.filter !== '') query.filter = this.filter
        if (this.order !== '') query.order = this.order
        if (page !== 1) query.page = page
        this.$router.push({
          name: 'SnsTimeline',
          params: this.$route.params,
          query: query
        })
      }
    },
    // ブロック・アングロック
    block() {
      this.axios
          .post(`${this.env.api_base_url}sns/block.json`, {
            user_id: this.fetch_data.space.user.id
          })
          .then(() => {
            this.fetch_data.space.user.block = true
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },
    unBlock() {
      this.axios
          .post(`${this.env.api_base_url}sns/un_block.json`, {
            user_id: this.fetch_data.space.user.id
          })
          .then(() => {
            this.fetch_data.space.user.block = false
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },

    //ミュート・アンミュート
    mute() {
      this.axios
          .post(`${this.env.api_base_url}sns/mute.json`, {
            user_id: this.fetch_data.space.user.id
          })
          .then(() => {
            this.fetch_data.space.user.mute = true
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },
    unMute() {
      this.axios
          .post(`${this.env.api_base_url}sns/un_mute.json`, {
            user_id: this.fetch_data.space.user.id
          })
          .then(() => {
            this.fetch_data.space.user.mute = false
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },
  }
}
</script>
